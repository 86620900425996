import React from 'react';
import ReactDOM from 'react-dom';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { keycloakClient, keycloakInitConfig } from './config/keycloak';
import ApiProvider from './services';

console.log(process.env.NODE_ENV);

ReactDOM.render(
  <React.StrictMode>
    <ReactKeycloakProvider
      authClient={keycloakClient}
      initOptions={keycloakInitConfig}
    >
      <ApiProvider>
        <App />
      </ApiProvider>
    </ReactKeycloakProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

reportWebVitals();
