import React, { createContext, useContext, useMemo } from 'react';
import { AdapterProfilesService } from './adapter.profiles.service';
import { FakerProfilesService } from './faker.profiles.service';
import { ProfilesContextData } from './types';

const ProfilesContext = createContext<ProfilesContextData>(
  {} as ProfilesContextData,
);

function factoryProfilesService(): ProfilesContextData {
  if (process.env.NODE_ENV === 'development') {
    if (process.env.REACT_APP_HOMOLOG) {
      return new AdapterProfilesService();
    }
    return new FakerProfilesService();
  }
  return new AdapterProfilesService();
}

export const ProfilesProvider: React.FC = ({ children }) => {
  const service = useMemo(() => factoryProfilesService(), []);

  return (
    <ProfilesContext.Provider value={service}>
      {children}
    </ProfilesContext.Provider>
  );
};

export function useProfiles(): ProfilesContextData {
  const context = useContext(ProfilesContext);

  if (!context) {
    throw new Error('useProfiles must be used within an ProfilesProvider');
  }

  return context;
}
