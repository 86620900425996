/* eslint-disable react/no-children-prop */
import {
  AlertDialog,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogOverlay,
  Button,
  useToast,
} from '@chakra-ui/react';
import React, { useContext, useRef, useState } from 'react';
import { LockIcon } from '@chakra-ui/icons';
import { Formik } from 'formik';
import { Grid } from '@material-ui/core';
import { DialogBody, DialogFooter, DialogHeader } from './styles';
import { PasswordContext } from '../../contexts';
import { useConfig } from '../../services/config';
import { ChakraInput } from '..';
import schema from './validators/schema';

const DialogPassword: React.FC = () => {
  const { isOpen, onClose } = useContext(PasswordContext);
  const [loading, setLoading] = useState(false);
  const cancelRef = useRef(null);
  const toast = useToast();
  const { newPassword } = useConfig();

  const showToast = (status: string) => {
    setLoading(true);
    if (status === 'success') {
      setLoading(false);
      toast({
        title: 'Senha alterada com sucesso',
        status: 'success',
        duration: 2000,
        isClosable: true,
      });
      onClose();
    } else {
      setLoading(false);
      toast({
        title: 'Falha ao alterar senha',
        status: 'error',
        duration: 2000,
        isClosable: true,
      });
    }
  };
  return (
    <AlertDialog
      leastDestructiveRef={cancelRef}
      onClose={onClose}
      isOpen={isOpen}
      size="xl"
      motionPreset="slideInBottom"
      closeOnEsc
      closeOnOverlayClick
      isCentered
      scrollBehavior="inside"
      blockScrollOnMount
      preserveScrollBarGap
    >
      <AlertDialogOverlay />
      <AlertDialogContent>
        <Formik
          enableReinitialize
          initialValues={{
            newPassword: '',
            confirmPassword: '',
          }}
          validationSchema={schema}
          onSubmit={async values => {
            setLoading(true);
            try {
              const response = await newPassword(values.newPassword);
              if (!response.error) {
                showToast('success');
              } else {
                showToast('error');
              }
            } catch (error) {
              showToast('error');
            }
          }}
        >
          {({ handleSubmit }) => (
            <>
              <form onSubmit={handleSubmit}>
                <DialogHeader>
                  <h3>Alterar sua senha</h3>
                </DialogHeader>
                <AlertDialogCloseButton />
                <DialogBody>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <ChakraInput
                        name="newPassword"
                        label="Nova Senha"
                        placeholder="Digite a sua nova senha"
                        icon={LockIcon}
                        password
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <ChakraInput
                        name="confirmPassword"
                        label="Confirme a Nova Senha"
                        placeholder="Confirme a nova senha"
                        icon={LockIcon}
                        password
                      />
                    </Grid>
                  </Grid>
                </DialogBody>
                <DialogFooter>
                  <Button
                    onClick={() => handleSubmit()}
                    colorScheme="success"
                    isLoading={loading}
                  >
                    Salvar
                  </Button>
                  <Button colorScheme="red" ref={cancelRef} onClick={onClose}>
                    Cancelar
                  </Button>
                </DialogFooter>
              </form>
            </>
          )}
        </Formik>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default DialogPassword;
