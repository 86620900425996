/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import { IGroupPermissionApi } from '../../utils/data/types';
import api from '../api';
import { ApiResponse } from '../types';
import { DataProfile } from './data';
import {
  EditProfileRequest,
  ListProfilesResponse,
  NewProfileRequest,
  ProfilesContextData,
} from './types';

export class AdapterProfilesService implements ProfilesContextData {
  async deleteProfile(
    id: string,
    statusProfile: boolean,
  ): Promise<ApiResponse<any>> {
    const update = { statusProfile: Boolean(statusProfile) };
    const { data } = await api.patch(`/company/profiles/${id}/status`, update);
    if (data) {
      return {
        error: false,
        data,
      };
    }
    return {
      error: true,
      data,
    };
  }

  async editProfile(
    id: string,
    editProfileRequest: EditProfileRequest,
  ): Promise<ApiResponse<any>> {
    const { name, groupPermissions } = editProfileRequest;
    const editProfile = {
      name,
      permissions: groupPermissions,
    };
    const { data } = await api.patch(`/company/profiles/${id}`, editProfile);
    if (data) {
      return {
        error: false,
        message: data,
      };
    }
    return {
      error: true,
      message: data,
    };
  }

  listProfiles(): Promise<ListProfilesResponse> {
    return Promise.resolve({
      error: false,
      data: DataProfile,
    });
  }

  async newProfile(
    newProfileRequest: NewProfileRequest,
  ): Promise<ApiResponse<any>> {
    const { name, groupPermissions } = newProfileRequest;
    const newProfile = {
      customProfile: {
        name,
        permissions: groupPermissions,
      },
    };
    const { data } = await api.post(`/company/profiles`, newProfile);
    if (data) {
      return {
        error: false,
        message: data,
      };
    }
    return {
      error: true,
      message: data,
    };
  }

  async getPermissions(): Promise<ApiResponse<IGroupPermissionApi>> {
    const { data } = await api.get('/permissions');
    if (data) {
      return {
        error: false,
        data,
      };
    }
    return {
      error: true,
      data,
    };
  }
}
