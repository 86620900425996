import { createContext } from 'react';
import { UserModel } from '../../utils/models';

interface SettingButtonContextData {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  user: UserModel | null;
  okAction: (...args: any) => void;
}

export default createContext<SettingButtonContextData>(
  {} as SettingButtonContextData,
);
