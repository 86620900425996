import styled from 'styled-components';
import { Button, SimpleGrid, Text } from '@chakra-ui/react';

export const AlertCustom = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
  height: 200px;
  border-radius: 10px;
  padding: 10px 10px;
  background-color: rgb(254, 235, 200);
  position: relative;
  line-height: 2;
  overflow-y: scroll;

  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
    color: teal;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  & {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
`;

AlertCustom.defaultProps = {
  status: 'warning',
  variant: 'subtle',
} as any;

export const ClosedButton = styled(Button)`
  position: absolute;
  align-self: flex-end;
  padding: 0 8px;
  top: 0;
  right: 0;
  border-radius: 50px;
  &: after {
    content: 'X';
  }
`;
ClosedButton.defaultProps = {
  colorScheme: 'teal',
  size: 'xsm',
};

export const LinkButton = styled(Button)`
  padding: 3px 5px;
  margin: 5px 10px;
  border-radius: 20px;
  font-weight: bold;
`;
LinkButton.defaultProps = {
  size: 'xsm',
  isExternal: true,
  border: '3px solid teal',
  color: 'teal',
};

export const TitleAlert = styled(Text)`
  font-size: 1.2rem;
  font-weight: bold;
  color: 'teal';
`;

export const ContentAlert = styled(Text)`
  font-size: 1.2rem;
  line-height: 1.3;
  margin: 0 0 10px;
  padding: 0 15%;
  overflow-wrap: break-word;
`;

export const SimpleGridAlert = styled(SimpleGrid)``;
SimpleGridAlert.defaultProps = {
  minChildWidth: '120px',
  spacing: 1,
};
