import {
  AlertDialog,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogOverlay,
  Button,
  ButtonProps,
} from '@chakra-ui/react';
import React, { useContext, useRef } from 'react';
import { DialogContext } from '../../contexts';
import { DialogBody, DialogFooter, DialogHeader } from './styles';

interface Props extends ButtonProps {
  title: string;
  cancelLabel?: string;
  okLabel?: string;
  loadingOk?: boolean;
  idAction: string;
}

const DialogButton: React.FC<Props> = ({
  title,
  cancelLabel = 'Cancelar',
  okLabel = 'Salvar',
  loadingOk = false,
  idAction,
  children,
}) => {
  const { isOpen, onClose, okAction } = useContext(DialogContext);
  const cancelRef = useRef(null);
  return (
    <>
      <AlertDialog
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isOpen={isOpen}
        size="lg"
        motionPreset="slideInBottom"
        closeOnEsc
        closeOnOverlayClick
        isCentered
        blockScrollOnMount
        preserveScrollBarGap
      >
        <AlertDialogOverlay />

        <AlertDialogContent>
          <DialogHeader>
            <h3>{title}</h3>
          </DialogHeader>
          <AlertDialogCloseButton />
          <DialogBody>{children}</DialogBody>
          <DialogFooter>
            <Button
              colorScheme="success"
              onClick={() => okAction(idAction)}
              isLoading={loadingOk}
            >
              {okLabel}
            </Button>
            <Button colorScheme="red" ref={cancelRef} onClick={onClose}>
              {cancelLabel}
            </Button>
          </DialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

export default DialogButton;
