/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import api from '../api';
import { ApiResponse, ApiStatus } from '../types';

import { DataPefin } from './data';
import { AnnotationEntity, DebtorEntity } from './models';
import { PefinRequest, PefinResponse, SerasaContextData } from './types';

function parseToString(data: string) {
  const parsedData = data
    .replace(/\(/g, '')
    .replace(/\)/g, '')
    .replace(/-/g, '')
    .replace(/\./g, '')
    .replace(/\./g, '')
    .replace(/ /g, '');
  return parsedData;
}

function parseDateToISO(data: string) {
  const splitData = data.split('/');
  return new Date(+splitData[2], +splitData[1] - 1, +splitData[0]);
}
export class AdapterSerasaService implements SerasaContextData {
  async getPefin(id: string): Promise<ApiResponse<PefinResponse>> {
    const { data } = await api.get<PefinResponse>(
      `/company/serasa/perfin/${id}`,
    );
    if (data) {
      return {
        error: false,
        data,
      };
    }
    return {
      error: true,
    };
  }

  async findAllPefins(): Promise<ApiResponse<PefinResponse[]>> {
    const { data } = await api.get<
      {
        id: string;
        cnpj: string;
        annotation: AnnotationEntity;
        debtor: DebtorEntity;
        createdAt: Date;
        status: boolean;
      }[]
    >(`/company/serasa/perfin`);
    const result = data.map(
      ({ id, cnpj, annotation, debtor, createdAt, status }) => ({
        id,
        cnpj,
        status,
        date: createdAt,
        debtor,
        annotation,
      }),
    );
    if (data) {
      return {
        error: false,
        data: result,
      };
    }
    return {
      error: true,
    };
  }

  lastestReports(): Promise<ApiResponse<PefinResponse[]>> {
    throw new Error('Method not implemented.');
  }

  deletePefin(id: string): Promise<ApiStatus> {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve({
          error: false,
        });
      }, 1000);
    });
  }

  async registerPefin({
    debtor,
    annotation,
  }: PefinRequest): Promise<ApiStatus> {
    const newDebtor = {
      ...debtor,
      document: {
        type: debtor.document.type,
        value: parseToString(debtor.document.value),
      },
      address: {
        complement: debtor.address.complement,
        county: debtor.address.county,
        uf: debtor.address.uf,
        cep: parseToString(debtor.address.cep),
        line: debtor.address.line,
      },
      birthDate: parseDateToISO(debtor.birthDate),
      phone: parseToString(debtor.phone),
    };
    const newAnnotation = {
      ...annotation,
      title: {
        species: annotation.title.species,
        number: annotation.title.number,
        value: Number(annotation.title.value),
        balanceAmount: Number(annotation.title.balanceAmount),
      },
      emissionDate: parseDateToISO(annotation.emissionDate),
      dueDate: parseDateToISO(annotation.dueDate),
    };
    await api.post(`/company/serasa/perfin`, {
      data: { debtor: newDebtor, annotation: newAnnotation },
    });
    return {
      error: false,
    };
  }
}
