import { createContext } from 'react';

interface BoxContextData {
  titleBreadcrumb?: string;
  pathBreadcrumb?: string;
  titleButton?: string;
  pathButton?: string;
  reloadAction?: () => Promise<void>;
  message? : string;
  messageTooltip?: string;
}

export default createContext<BoxContextData>({} as BoxContextData);
