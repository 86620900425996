/* eslint-disable dot-notation */
/* eslint-disable react/no-children-prop */
import {
  FormControl,
  FormErrorMessage,
  Checkbox,
  CheckboxProps
} from '@chakra-ui/react';
import { Field } from 'formik';
import React from 'react';
import { getErrorUtils } from '../../utils/error';

interface ChakraCheckboxProps extends CheckboxProps {
  name: string;
  disabled?: boolean;
  defaultChecked?: boolean;
}

const ChakraCheckBox: React.FC<ChakraCheckboxProps> = ({
  name,
  disabled = false,
  defaultChecked = false,
  ...rest
}) => {
  return (
    <Field name={name}>
      {({ field, form }: any) => {
        return (
          <FormControl
            style={{ width: 'unset' }}
            isInvalid={
              getErrorUtils(form.errors, name) &&
              getErrorUtils(form.touched, name)
            }
          >
            <Checkbox
              id={name}
              colorScheme="success"
              size="lg"
              disabled={disabled}
              defaultChecked={defaultChecked}
              {...field}
              {...rest}
            />
            <FormErrorMessage>
              {getErrorUtils(form.errors, name)}
            </FormErrorMessage>
          </FormControl>
        );
      }}
    </Field>
  );
};

export default ChakraCheckBox;
