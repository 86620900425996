import { v4 as uuidv4 } from 'uuid';
import { CreditEntity } from '../models';

const nowDate = new Date();

export const DataCredit: CreditEntity[] = [
  {
    id: uuidv4(),
    name: 'Aços Motta',
    email: 'contato@acosmotta.com.br',
    cpf: 41063768063,
    cnpj: 45583383000106,
    login: 'valery.oliveira',
    date: nowDate,
  },
  {
    id: uuidv4(),
    name: 'Aços Motta',
    email: 'contato@acosmotta.com.br',
    cpf: 41063768063,
    cnpj: 45583383000106,
    login: 'valery.oliveira',
    date: nowDate,
  },
  {
    id: uuidv4(),
    name: 'Aços Motta',
    email: 'contato@acosmotta.com.br',
    cpf: 41063768063,
    cnpj: 45583383000106,
    login: 'valery.oliveira',
    date: nowDate,
  },
  {
    id: uuidv4(),
    name: 'Aços Motta',
    email: 'contato@acosmotta.com.br',
    cpf: 41063768063,
    cnpj: 45583383000106,
    login: 'valery.oliveira',
    date: nowDate,
  },
  {
    id: uuidv4(),
    name: 'Aços Motta',
    email: 'contato@acosmotta.com.br',
    cpf: 41063768063,
    cnpj: 45583383000106,
    login: 'valery.oliveira',
    date: nowDate,
  },
  {
    id: uuidv4(),
    name: 'Aços Motta',
    email: 'contato@acosmotta.com.br',
    cpf: 41063768063,
    cnpj: 45583383000106,
    login: 'valery.oliveira',
    date: nowDate,
  },
  {
    id: uuidv4(),
    name: 'Aços Motta',
    email: 'contato@acosmotta.com.br',
    cpf: 41063768063,
    cnpj: 45583383000106,
    login: 'valery.oliveira',
    date: nowDate,
  },
  {
    id: uuidv4(),
    name: 'Aços Motta',
    email: 'contato@acosmotta.com.br',
    cpf: 41063768063,
    cnpj: 45583383000106,
    login: 'valery.oliveira',
    date: nowDate,
  },
  {
    id: uuidv4(),
    name: 'Aços Motta',
    email: 'contato@acosmotta.com.br',
    cpf: 41063768063,
    cnpj: 45583383000106,
    login: 'valery.oliveira',
    date: nowDate,
  },
  {
    id: uuidv4(),
    name: 'Aços Motta',
    email: 'contato@acosmotta.com.br',
    cpf: 41063768063,
    cnpj: 45583383000106,
    login: 'valery.oliveira',
    date: nowDate,
  },
  {
    id: uuidv4(),
    name: 'Aços Motta',
    email: 'contato@acosmotta.com.br',
    cpf: 41063768063,
    cnpj: 45583383000106,
    login: 'valery.oliveira',
    date: nowDate,
  },
  {
    id: uuidv4(),
    name: 'Aços Motta',
    email: 'contato@acosmotta.com.br',
    cpf: 41063768063,
    cnpj: 45583383000106,
    login: 'valery.oliveira',
    date: nowDate,
  },
  {
    id: uuidv4(),
    name: 'Aços Motta',
    email: 'contato@acosmotta.com.br',
    cpf: 41063768063,
    cnpj: 45583383000106,
    login: 'valery.oliveira',
    date: nowDate,
  },
  {
    id: uuidv4(),
    name: 'Aços Motta',
    email: 'contato@acosmotta.com.br',
    cpf: 41063768063,
    cnpj: 45583383000106,
    login: 'valery.oliveira',
    date: nowDate,
  },
  {
    id: uuidv4(),
    name: 'Aços Motta',
    email: 'contato@acosmotta.com.br',
    cpf: 41063768063,
    cnpj: 45583383000106,
    login: 'valery.oliveira',
    date: nowDate,
  },
  {
    id: uuidv4(),
    name: 'Aços Motta',
    email: 'contato@acosmotta.com.br',
    cpf: 41063768063,
    cnpj: 45583383000106,
    login: 'valery.oliveira',
    date: nowDate,
  },
  {
    id: uuidv4(),
    name: 'Aços Motta',
    email: 'contato@acosmotta.com.br',
    cpf: 41063768063,
    cnpj: 45583383000106,
    login: 'valery.oliveira',
    date: nowDate,
  },
  {
    id: uuidv4(),
    name: 'Aços Motta',
    email: 'contato@acosmotta.com.br',
    cpf: 41063768063,
    cnpj: 45583383000106,
    login: 'valery.oliveira',
    date: nowDate,
  },
  {
    id: uuidv4(),
    name: 'Aços Motta',
    email: 'contato@acosmotta.com.br',
    cpf: 41063768063,
    cnpj: 45583383000106,
    login: 'valery.oliveira',
    date: nowDate,
  },
  {
    id: uuidv4(),
    name: 'Aços Motta',
    email: 'contato@acosmotta.com.br',
    cpf: 41063768063,
    cnpj: 45583383000106,
    login: 'valery.oliveira',
    date: nowDate,
  },
  {
    id: uuidv4(),
    name: 'Aços Motta',
    email: 'contato@acosmotta.com.br',
    cpf: 41063768063,
    cnpj: 45583383000106,
    login: 'valery.oliveira',
    date: nowDate,
  },
  {
    id: uuidv4(),
    name: 'Aços Motta',
    email: 'contato@acosmotta.com.br',
    cpf: 41063768063,
    cnpj: 45583383000106,
    login: 'valery.oliveira',
    date: nowDate,
  },
];
