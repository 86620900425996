import { Button } from '@chakra-ui/react';
import styled from 'styled-components';

export const Container = styled.div`
  padding-top: 0;
  width: 100%;
  overflow-x: auto;
  table {
    border-collapse: separate;
    border-spacing: 0 0.25em;
    white-space: nowrap;
    thead {
      background-color: #1e4280;
      font-weight: bold;

      tr > th {
        border: 0;
        color: white;

        padding: 16px 8px;
        font-size: 14px;
        font-weight: 500;
        text-align: center;
        text-transform: unset;

        &:first-child {
          border-radius: 12px 0 0 12px;
        }
        &:last-child {
          border-radius: 0 12px 12px 0;
        }
      }
    }
    tbody {
      tr {
        cursor: text;
        transition: all 0.2s ease-in-out;
        border-radius: 16px;
        & > td:first-child {
          border-radius: 12px 0 0 12px;
        }
        & > td:last-child {
          border-radius: 0 12px 12px 0;
        }

        &:hover {
          // background-color: #fef4ef;
          background-color: aliceblue;

          /* transform: translateY(-2px); */
        }
      }
      tr > td {
        border: 0;
        padding: 12px 8px;
        font-size: 14px;
        font-weight: 500;
        text-align: center;
        text-transform: unset;
      }
    }
  }

  &::-webkit-scrollbar-track {
    border-radius: 10px;
    border: solid 16px transparent;
    background-color: #f1f4f7;
  }

  &::-webkit-scrollbar {
    width: 12px;
    height: 12px;
  }

  &::-webkit-scrollbar-thumb {
    height: 16px;
    border: 2px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 12px;
    background-color: #cbd5e0;

    &:hover {
      background-color: #c0cddb;
    }
  }
`;

export const Pagination = styled.div`
  display: flex;
  justify-content: center;
  gap: 8px;
  padding: 8px 0;
`;

interface PageProps {
  active?: boolean;
}

export const PageButton = styled(Button)<PageProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: bold;
  border-radius: 12px;
`;

export const EmptyContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  height: 200px;
  width: 100%;

  > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #a0aec0;
    h3 {
      font-weight: bold;
    }
  }
`;
