/* eslint-disable react/require-default-props */
import {
  HStack,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Tag,
  TagLabel,
  TagLeftIcon,
  useDisclosure,
} from '@chakra-ui/react';
import React from 'react';
import { FaQuestionCircle } from 'react-icons/fa';

import {
  BreakLineContainer,
  Container,
  DialogBody,
  DialogFooter,
  DialogHeader,
} from './styles';

interface Props {
  title: string;
  description: string;
}

const InfoBadge: React.FC<Props> = ({ title, description }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <Container>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        size="3xl"
        motionPreset="slideInBottom"
        isCentered
        closeOnEsc
        closeOnOverlayClick
        scrollBehavior="inside"
        blockScrollOnMount
        preserveScrollBarGap
      >
        <ModalOverlay />
        <ModalContent>
          <DialogHeader>
            <h3>{title}</h3>
          </DialogHeader>
          <ModalCloseButton />
          <DialogBody>
            <BreakLineContainer>{description}</BreakLineContainer>
          </DialogBody>
          <DialogFooter />
        </ModalContent>
      </Modal>
      <HStack spacing={4} onClick={onOpen}>
        <Tag
          size="md"
          variant="outline"
          borderRadius="full"
          colorScheme="primary"
        >
          <TagLeftIcon as={FaQuestionCircle} />
          <TagLabel>Informações</TagLabel>
        </Tag>
      </HStack>
    </Container>
  );
};

export default InfoBadge;
