/* eslint-disable no-shadow */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import api from '../api';
import { ApiResponse, ApiStatus } from '../types';
import { TypeReport } from './models/type.enum';
import {
  Details,
  ReportsContextData,
  ReportsResponse,
  RequestBy,
} from './types';

export class AdapterReportsService implements ReportsContextData {
  async lastestReports(id?: string): Promise<ApiResponse<ReportsResponse[]>> {
    const { data } = await api.get<
      {
        id: string;
        requestedBy: RequestBy;
        details: Details;
        detailsId: string;
        type: string;
        createdAt: Date;
      }[]
    >(`/company/reports`);

    const result = data.map(
      ({ id, requestedBy, details, detailsId, type, createdAt }) => {
        const { user } = requestedBy;
        const cnpjSelected =
          type === TypeReport.MONITOR ? details.monitored : details.cnpj;
        return {
          id,
          user: String(user),
          cnpj: cnpjSelected || 'Desconhecido',
          type,
          date: createdAt,
          details,
          detailsId,
          requestedBy,
        };
      },
    );
    if (data) {
      return {
        error: false,
        data: result,
      };
    }
    return {
      error: true,
    };
  }
}
