import React, { createContext, useContext, useMemo } from 'react';
import { AdapterAlertsService } from './adapter.alerts.service';
import { AlertsContextData } from './types';

const AlertsContext = createContext<AlertsContextData>({} as AlertsContextData);

const factoryAlertsService = (): AlertsContextData => {
  if (process.env.NODE_ENV === 'development') {
    if (process.env.REACT_APP_HOMOLOG) {
      return new AdapterAlertsService();
    }
  }
  return new AdapterAlertsService();
};

export const AlertsProvider: React.FC = ({ children }) => {
  const service = useMemo(() => factoryAlertsService(), []);

  return (
    <AlertsContext.Provider value={service}>{children}</AlertsContext.Provider>
  );
};

export function useAlerts(): AlertsContextData {
  const context = useContext(AlertsContext);
  if (!context) {
    throw new Error('useAlerts must be used within an AlertsProvider');
  }

  return context;
}
