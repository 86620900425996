import api from '../api';
import { ApiData } from '../types';
import { AlertsBaseResponse, UserListAlert } from './types';

export class AdapterAlertsService {
  async updateViewOfUser(alertId: string): Promise<ApiData<any>> {
    const { data } = await api.patch<UserListAlert>(`/alerts/${alertId}/view`);
    if (data) {
      return {
        error: false,
        data,
      };
    }
    return {
      error: true,
      data: [],
    };
  }

  async getAlertForUser(): Promise<ApiData<AlertsBaseResponse[]>> {
    const { data } = await api.get<AlertsBaseResponse[]>(`/alerts/user`);
    if (data) {
      return {
        error: false,
        data,
      };
    }
    return {
      error: true,
      data: [],
    };
  }
}
