import React, { useState, useEffect, useCallback } from 'react';
import { Link } from '@chakra-ui/react';

import { useAlerts } from '../../services/alerts';
import {
  AlertCustom,
  ClosedButton,
  LinkButton,
  TitleAlert,
  ContentAlert,
  SimpleGridAlert,
} from './styles';
import { AlertsBaseResponse } from '../../services/alerts/types';

const Alerts: React.FC = () => {
  const { getAlertForUser, updateViewOfUser } = useAlerts();
  const [alerts, setAlerts] = useState<AlertsBaseResponse[]>([]);

  const fetchAlerts = useCallback(async () => {
    try {
      const response = await getAlertForUser();
      if (response.data) {
        setAlerts(response.data);
      }
    } catch (error) {
      setAlerts([]);
    }
  }, [alerts]);

  const closeAlert = async (alertId: string) => {
    try {
      const response = await updateViewOfUser(alertId);
      if (response.data) {
        fetchAlerts();
      }
    } catch (error) {
      console.log(error);
    }
  };
  const randomNumber = (length: number): number => {
    const number = Number(length || 1);
    const random = Math.floor(Math.random() * number);
    return random;
  };

  const filterAlertsForUser = (alert: AlertsBaseResponse) => {
    const { usersList } = alert;
    if (usersList.length === 0) {
      return alert;
    }
    if (usersList.length && usersList[0] && usersList[0].view === false) {
      return usersList[0].view === false;
    }
    return null;
  };

  useEffect(() => {
    fetchAlerts();
  }, []);
  return (
    <SimpleGridAlert>
      {alerts.length
        ? alerts
            .slice(randomNumber(alerts.length), alerts.length)
            .filter(alert => filterAlertsForUser(alert))
            .map(alert => {
              const { _id, name, description, href } = alert;
              return (
                <AlertCustom>
                  <ClosedButton onClick={() => closeAlert(_id)} />
                  <TitleAlert>{name}!</TitleAlert>
                  <ContentAlert>{description}</ContentAlert>
                  {href !== '' ? (
                    <LinkButton as={Link} href={href}>
                      Saiba mais
                    </LinkButton>
                  ) : null}
                </AlertCustom>
              );
            })
        : null}
    </SimpleGridAlert>
  );
};

export default Alerts;
