/* eslint-disable no-unused-vars */

import { ListProfilesResponse } from '../../services/config/types';

/* eslint-disable no-shadow */
export enum ProfileType {
  ADMIN = 'Admin',
  EDITOR = 'Editor',
}

export enum SituationType {
  ACTIVE = 'Ativo',
  INACTIVE = 'Inativo',
}

export interface UserModel {
  id: string;
  email: string;
  name: string;
  status: string;
  cellphone: string;
  phone: string;
  profile: ListProfilesResponse;
  date: string;
}
