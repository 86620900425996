/* eslint-disable no-restricted-globals */
import React, { useEffect, useState } from 'react';
import { Header, SideMenu } from '..';
import { MenuContext } from '../../contexts';
import { LeftAnimationOpacity } from '../../utils/variants';
import Footer from '../footer';

import { Container, Content, ContentContainer, Main } from './styles';

const PageContainer: React.FC = ({ children }) => {
  const [isOpenMenu, setIsOpenMenu] = useState(!(innerWidth <= 996));

  useEffect(() => {
    function handleResize() {
      setIsOpenMenu(!(innerWidth <= 996));
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <Container>
      <MenuContext.Provider
        value={{
          isOpen: isOpenMenu,
          setIsOpen: setIsOpenMenu,
          isMobile: innerWidth <= 996 && isOpenMenu,
        }}
      >
        <SideMenu />
        <ContentContainer className={`${!isOpenMenu ? 'fullWidth' : 'unset'}`}>
          <Header />
          <Main
            variants={LeftAnimationOpacity}
            initial="hidden"
            animate="visible"
            exit="exit"
          >
            <Content>{children}</Content>
            <Footer />
          </Main>
        </ContentContainer>
      </MenuContext.Provider>
    </Container>
  );
};

export default PageContainer;
