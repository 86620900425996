/* eslint-disable no-unused-vars */
import { createContext } from 'react';

export interface PasswordContextData {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
}

export default createContext<PasswordContextData>({} as PasswordContextData);
