import { v4 as uuidv4 } from 'uuid';
import { ProfileType, SituationType } from '../../../utils/models';
import { UserEntity } from '../models';

const nowDate = new Date();

export const DataUsers: UserEntity[] = [
  {
    id: uuidv4(),
    name: 'Valery Oliveira',
    email: 'valery.oliveira@teddysolucoes.com',
    user: 'valery.oliveira',
    cpf: 41063768063,
    cnpj: 68689376000199,
    cellphone: 11965891323,
    landline: 1142101726,
    profile: ProfileType.ADMIN,
    situation: SituationType.ACTIVE,
    date: nowDate,
  },
  {
    id: uuidv4(),
    name: 'Silvia Linares',
    email: 'silvia.linares@teddysolucoes.com',
    user: 'silvia.linares',
    cpf: 41063768063,
    cnpj: 68689376000199,
    cellphone: 1142101726,
    landline: 11965891323,
    profile: ProfileType.EDITOR,
    situation: SituationType.INACTIVE,
    date: nowDate,
  },
  {
    id: uuidv4(),
    name: 'Giovanna Godinho',
    email: 'giovanna.godinho@teddysolucoes.com',
    user: 'giovanna.godinho',
    cpf: 41063768063,
    cnpj: 68689376000199,
    cellphone: 11965891323,
    landline: 1142101726,
    profile: ProfileType.ADMIN,
    situation: SituationType.ACTIVE,
    date: nowDate,
  },
  {
    id: uuidv4(),
    name: 'Sistema',
    email: 'sistema@teddysolucoes.com',
    user: 'sistema',
    cpf: 41063768063,
    cnpj: 68689376000199,
    cellphone: 1142101726,
    landline: 11965891323,
    profile: ProfileType.EDITOR,
    situation: SituationType.ACTIVE,
    date: nowDate,
  },
];
