
import React from 'react';
import { Container } from './styles';
import  PrivacyPolicy from '../../assets/politica-de-privacidade-risk-cred.pdf';

const Footer: React.FC = () => {
  const nowYear = new Date().getFullYear();
  return (
    <Container>
      Copyright © {nowYear} RiskCred by Teddy® - Todos os direitos reservados{' '}
      <a href={PrivacyPolicy} target="_blank" rel="noreferrer">
        Política de Privacidade
      </a>
    </Container>
  );
};

export default Footer;
