/* eslint-disable no-nested-ternary */
/* eslint-disable dot-notation */
/* eslint-disable react/no-children-prop */
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import {
  ComponentWithAs,
  FormControl,
  FormErrorMessage,
  FormLabel,
  IconButton,
  IconProps,
  Input,
  InputGroup,
  InputLeftElement,
  InputProps,
  InputRightElement,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
} from '@chakra-ui/react';
import { Field } from 'formik';
import React from 'react';
import { IconType } from 'react-icons';
import InputMask from 'react-input-mask';
import { Skeleton } from '..';
import { getErrorUtils } from '../../utils/error';

interface ChakraInputProps extends InputProps {
  name: string;
  label: string;
  placeholder: string;
  icon: ComponentWithAs<'svg', IconProps> | IconType;
  type?: string;
  password?: boolean;
  money?: boolean;
  defaultValueMoney?: number;
  number?: boolean;
  phone?: boolean;
  disabled?: boolean;
  isLoading?: boolean;
  mask?: string;
  maskChar?: string;
}

const ChakraInput: React.FC<ChakraInputProps> = ({
  name,
  label,
  placeholder,
  icon: Icon,
  type = 'text',
  password = false,
  money = false,
  defaultValueMoney,
  number = false,
  phone = false,
  disabled = false,
  isLoading = false,
  mask,
  // maskChar,
  ...rest
}) => {
  const [showPassword, setShowPassword] = React.useState(false);
  const handleTogglePassowrd = () => setShowPassword(!showPassword);
  const parse = (value: any) =>
    value.toLocaleString('pt-br', { minimumFractionDigits: 2 });
  if (money || number) {
    return (
      <Field name={name}>
        {({ field, form }: any) => {
          return (
            <FormControl
              isInvalid={
                getErrorUtils(form.errors, name) &&
                getErrorUtils(form.touched, name)
              }
            >
              <FormLabel>{label}</FormLabel>
              <Skeleton isLoaded={!isLoading}>
                <NumberInput
                  defaultValue={defaultValueMoney}
                  min={0}
                  onChange={value =>
                    form.setFieldValue(field.name, parse(value))
                  }
                  precision={money ? 2 : undefined}
                  step={money ? 0.2 : undefined}
                >
                  <InputGroup>
                    <InputLeftElement
                      pointerEvents="none"
                      color="gray.300"
                      children={money ? 'R$' : <Icon />}
                    />
                    <NumberInputField
                      id={name}
                      focusBorderColor="secondary.200"
                      placeholder={placeholder}
                      disabled={disabled}
                      paddingLeft={10}
                      inputMode="decimal"
                      {...field}
                      {...rest}
                    />
                  </InputGroup>
                  <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                </NumberInput>
              </Skeleton>
              <FormErrorMessage>
                {getErrorUtils(form.errors, name)}
              </FormErrorMessage>
            </FormControl>
          );
        }}
      </Field>
    );
  }
  return (
    <Field name={name}>
      {({ field, form }: any) => {
        return (
          <FormControl
            isInvalid={
              getErrorUtils(form.errors, name) &&
              getErrorUtils(form.touched, name)
            }
          >
            <FormLabel>{label}</FormLabel>
            <Skeleton isLoaded={!isLoading}>
              <InputMask
                mask={phone ? '(99) 9999tt999?' : mask}
                formatChars={{ '9': '[0-9]', t: '[0-9-]', '?': '[0-9 ]' }}
                maskChar={phone ? null : '_'}
                {...rest}
                {...field}
              >
                {() => (
                  <InputGroup>
                    <InputLeftElement
                      pointerEvents="none"
                      color="gray.300"
                      children={money ? 'R$' : <Icon />}
                    />
                    <Input
                      id={name}
                      type={
                        password ? (showPassword ? 'text' : 'password') : type
                      }
                      focusBorderColor="secondary.200"
                      placeholder={placeholder}
                      disabled={disabled}
                      {...field}
                      {...rest}
                    />
                    {password && !disabled && (
                      <InputRightElement width="4.5rem">
                        <IconButton
                          variant="link"
                          colorScheme="gray"
                          aria-label="Toggle Password"
                          fontSize="20px"
                          icon={showPassword ? <ViewIcon /> : <ViewOffIcon />}
                          onClick={handleTogglePassowrd}
                        />
                      </InputRightElement>
                    )}
                  </InputGroup>
                )}
              </InputMask>
            </Skeleton>
            <FormErrorMessage>
              {getErrorUtils(form.errors, name)}
            </FormErrorMessage>
          </FormControl>
        );
      }}
    </Field>
  );
};

export default ChakraInput;
