import React from 'react';
import { AuthProvider } from '../hooks/auth';
import { ConfigProvider } from './config';
import { CreditProvider } from './credit';
import { ProfilesProvider } from './profiles';
import { ReportsProvider } from './reports';
import { SerasaProvider } from './serasa';
import { AlertsProvider } from './alerts';

const ApiProvider: React.FC = ({ children }) => {
  return (
    <ConfigProvider>
      <AuthProvider>
        <AlertsProvider>
          <ProfilesProvider>
            <ReportsProvider>
              <CreditProvider>
                <SerasaProvider>{children}</SerasaProvider>
              </CreditProvider>
            </ReportsProvider>
          </ProfilesProvider>
        </AlertsProvider>
      </AuthProvider>
    </ConfigProvider>
  );
};

export default ApiProvider;
