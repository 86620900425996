import { ComponentWithAs, IconProps } from '@chakra-ui/react';
import {
  ConfigIcon,
  CreditoIcon,
  SerasaIcon,
} from '../../styles/chakra/icons';
import { FeatureModel, ProductModel } from '../models';

export interface SideMenuOption extends FeatureModel {
  id: string;
  path: string;
  redirect: boolean;
}

export interface SideMenuCategory extends ProductModel {
  icon: ComponentWithAs<'svg', IconProps>;
  features: SideMenuOption[];
  inComing?: boolean;
}

export const SideMenuData: SideMenuCategory[] = [
  {
    id: '1',
    name: 'Crédito',
    icon: CreditoIcon,
    disabled: false,
    features: [
      {
        id: 'credit-relatorios',
        name: 'Relatório de Crédito',
        path: '/credito/relatorio-credito',
        disabled: false,
        redirect: false,
      },
      {
        id: 'credit-monitore',
        name: 'Monitore',
        path: '/credito/monitore',
        disabled: false,
        redirect: false,
      },
      {
        id: 'credit-relatorios',
        name: 'Últimos Relatórios',
        path: '/ultimos-relatorios/credito',
        disabled: false,
        redirect: false,
      },
    ],
  },
  {
    id: '2',
    name: 'Serasa',
    icon: SerasaIcon,
    disabled: false,
    features: [
      {
        id: 'serasa-consulta',
        name: 'Consulta Serasa',
        path: 'https://sitenet.serasa.com.br/Logon/',
        disabled: false,
        redirect: true,
      },
      {
        id: 'serasa-pefin',
        name: 'Pefin',
        path: '/serasa/pefin',
        disabled: false,
        redirect: false,
      }
    ],
  },
  {
    id: '3',
    icon: ConfigIcon,
    name: 'Configurações',
    disabled: false,
    features: [
      {
        id: 'tc-manager-users',
        name: 'Usuários',
        path: '/usuarios',
        disabled: false,
        redirect: false,
      },
      {
        id: 'tc-manager-profiles',
        name: 'Perfis',
        path: '/perfis',
        disabled: false,
        redirect: false,
      },
    ],
  },
];
