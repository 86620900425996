/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import {
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useDisclosure,
} from '@chakra-ui/react';
import { MdMoreHoriz, MdPowerSettingsNew } from 'react-icons/md';
import { HamburgerIcon, LockIcon } from '@chakra-ui/icons';
import { FaUser } from 'react-icons/fa';
import {
  Container,
  PlatformContainer,
  PlatformName,
  ProfileContainer,
  ProfileDropdown,
  ProfileInfo,
  Welcome,
} from './styles';
import { LeftAnimationOpacity } from '../../utils/variants';
import { MenuContext, PasswordContext } from '../../contexts';
import { useAuth } from '../../hooks/auth';
import { DialogPassword } from '..';

const Header: React.FC = () => {
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [isSticky, setSticky] = useState(false);
  const MenuModal = useContext(MenuContext);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { user, company, logout } = useAuth();

  const handleScroll = () => {
    const currentScrollPos = window.pageYOffset;
    setSticky(currentScrollPos > 12);
    setPrevScrollPos(currentScrollPos);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', () => handleScroll);
    };
  }, [prevScrollPos, isSticky, handleScroll]);

  return (
    <Container
      variants={LeftAnimationOpacity}
      initial="hidden"
      animate="visible"
      exit="exit"
      className={`${isSticky ? 'sticky' : 'unset'} ${
        !MenuModal.isOpen ? 'fullWidth' : 'unset'
      } `}
    >
      <PlatformContainer>
        <PlatformName className={`${isSticky ? ' sticky' : 'unset'}`}>
          <IconButton
            aria-label="Open Menu"
            icon={<HamburgerIcon />}
            colorScheme="primary"
            variant="outline"
            mr={4}
            className="menu-button"
            onClick={() => MenuModal.setIsOpen(!MenuModal.isOpen)}
          />
          RiskCred
        </PlatformName>
        <Welcome className={`${isSticky ? ' sticky' : 'unset'}`}>
          Bem vindo a sua <strong>Plataforma de Análise de Crédito</strong>
        </Welcome>
        <ProfileContainer>
          <ProfileInfo>
            <div>
              {company ? (
                <img src={company.logoImage} alt={company.name} />
              ) : (
                <FaUser size={20} />
              )}
            </div>
            <h4>
              {user ? `${user.firstName} ${user.lastName}` : 'Carregando...'}
            </h4>
          </ProfileInfo>
          <ProfileDropdown>
            <Menu>
              <MenuButton
                as={IconButton}
                aria-label="Opções"
                icon={<MdMoreHoriz />}
                size="xs"
                variant="outline"
                placement="bottom-end"
              />
              <MenuList>
                <MenuItem icon={<FaUser />}>
                  {user
                    ? `${user.firstName} ${user.lastName}`
                    : 'Carregando...'}
                </MenuItem>
                <MenuItem icon={<LockIcon />} onClick={() => onOpen()}>
                  Alterar Senha
                </MenuItem>
                <MenuItem
                  icon={<MdPowerSettingsNew />}
                  onClick={() => logout()}
                >
                  Sair do Sistema
                </MenuItem>
              </MenuList>
            </Menu>
            <PasswordContext.Provider
              value={{
                isOpen,
                onOpen,
                onClose,
              }}
            >
              <DialogPassword />
            </PasswordContext.Provider>
          </ProfileDropdown>
        </ProfileContainer>
      </PlatformContainer>
    </Container>
  );
};

export default Header;
