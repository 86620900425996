import styled from 'styled-components';

export const Container = styled.footer`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  padding-top: 48px;
  bottom: 16px;
  text-align: center;
  width: 100%;
  padding: 0 16px;
  gap: 8px;
  font-size: 0.9rem;
  a {
    color: var(--rc-color-primary);
    font-weight: 700;
    transition: all 0.2s ease-in-out;
    &:hover {
      color: var(--rc-color-primary);
    }
  }

  @media (max-width: 836px) {
    flex-direction: column;
    left: 50%;
    transform: translateX(-50%);
  }
`;
