import React, { lazy, Suspense } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import { useKeycloak } from '@react-keycloak/web';
import PrivateRoute from './privateroute';

import {  PageContainer } from '../components';
import Preloader from '../components/Preloader';
import { RoutesContainer } from '../styles/global';

const Alerts = lazy(() => import('../components/alerts'));
const Auth = lazy(() => import('../pages/auth'));
const BoaVista = lazy(() => import('../pages/boa-vista'));
const Credit = lazy(() => import('../pages/credit'));
const Dashboard = lazy(() => import('../pages/dashboard'));
const Profiles = lazy(() => import('../pages/profiles'));
const Reports = lazy(() => import('../pages/reports'));
const Serasa = lazy(() => import('../pages/serasa'));
const Users = lazy(() => import('../pages/users'));

const Routes: React.FC = () => {
  const location = useLocation();
  const { initialized } = useKeycloak();
  if (!initialized) {
    return <Preloader />;
  }
  return (
    <>
      {location.pathname !== '/' && location.pathname !== '/login' ? (
        <AnimatePresence>
          <PageContainer>
            <RoutesContainer>
              <Suspense fallback={<Preloader />}>
                <PrivateRoute path="/" component={Alerts} />
                <Switch location={location} key={location.key}>
                  <PrivateRoute exact path="/dashboard" component={Dashboard} />
                  <PrivateRoute path="/boa-vista" component={BoaVista} />
                  <PrivateRoute path="/credito" component={Credit} />
                  <PrivateRoute path="/serasa" component={Serasa} />
                  <PrivateRoute path="/ultimos-relatorios" component={Reports} />
                  <PrivateRoute path="/usuarios" component={Users} />
                  <PrivateRoute path="/perfis" component={Profiles} />
                  <Redirect to="/dashboard" exact />
                </Switch>
              </Suspense>
            </RoutesContainer>
          </PageContainer>
        </AnimatePresence>
      ) : (
        <Switch location={location} key={location.key}>
          <Suspense fallback={<Preloader />}>
            <Route path="/" component={Auth} />
          </Suspense>
        </Switch>
      )}
    </>
  );
};

export default Routes;
