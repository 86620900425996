/* eslint-disable react/no-children-prop */
import {
  AlertDialog,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogOverlay,
  Button,
  useToast,
} from '@chakra-ui/react';
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { FaUser } from 'react-icons/fa';
import { Formik } from 'formik';
import { Grid } from '@material-ui/core';
import { DialogBody, DialogFooter, DialogHeader } from './styles';
import { SettingButtonContext } from '../../contexts';
import { SituationType } from '../../utils/models';
import { useConfig } from '../../services/config';
import { ListProfilesResponse } from '../../services/config/types';
import { ChakraInput, LoadingAnimation } from '..';
import ChakraSelect from '../select';
import schema from './validators/schema';
import { useAuth } from '../../hooks/auth';

const DialogSettings: React.FC = () => {
  const { isOpen, onClose, user, okAction } = useContext(SettingButtonContext);
  const [loading, setLoading] = useState(false);
  const [profiles, setProfiles] = useState<ListProfilesResponse[]>([]);
  const [loadingForm, setLoadingForm] = useState(false);
  const cancelRef = useRef(null);
  const toast = useToast();
  const { listProfilesOption, editUser } = useConfig();
  const { isAdmin } = useAuth();

  const showToast = (status: string) => {
    setLoading(true);
    if (status === 'success') {
      setLoading(false);
      toast({
        title: 'Usuário alterado com sucesso',
        status: 'success',
        duration: 2000,
        isClosable: true,
      });
      onClose();
      setTimeout(() => {
        window.location.reload()
      }, 2010)
    } else {
      setLoading(false);
      toast({
        title: 'Falha ao alterar usuário',
        status: 'error',
        duration: 2000,
        isClosable: true,
      });
    }
  };

  const fetchProfiles = useCallback(async () => {
    setLoadingForm(true);
    try {
      const response = await listProfilesOption();
      if (response.data) {
        setProfiles(response.data);
      } else {
        setProfiles([]);
      }
      setLoadingForm(false);
    } catch {
      setProfiles([]);
      setLoadingForm(false);
      toast({
        title: 'Falha ao Buscar Perfis',
        status: 'error',
        duration: 2000,
        isClosable: true,
      });
    }
  }, [toast]);

  useEffect(() => {
    fetchProfiles();
  }, []);
  return (
    <>
      {user && (
        <AlertDialog
          leastDestructiveRef={cancelRef}
          onClose={onClose}
          isOpen={isOpen}
          size="xl"
          motionPreset="slideInBottom"
          closeOnEsc
          closeOnOverlayClick
          isCentered
          scrollBehavior="inside"
          blockScrollOnMount
          preserveScrollBarGap
        >
          <AlertDialogOverlay />
          <AlertDialogContent>
            <Formik
              enableReinitialize
              initialValues={
                !loadingForm
                  ? {
                      firstName: user.name.split(' ')[0],
                      lastName: user.name.replace(
                        `${user.name.split(' ')[0]} `,
                        '',
                      ),
                      status: user.status,
                      profileId: user.profile.id,
                    }
                  : {
                      firstName: '',
                      lastName: '',
                      status: '',
                      profileId: '',
                    }
              }
              validationSchema={schema}
              onSubmit={async values => {
                setLoading(true);
                try {
                  const response = await editUser(user.id, values);
                  if (!response.error) {
                    showToast('success');
                    okAction();
                  } else {
                    showToast('error');
                  }
                } catch (error) {
                  showToast('error');
                }
              }}
            >
              {({ handleSubmit }) => (
                <>
                  <form onSubmit={handleSubmit}>
                    <DialogHeader>
                      <h3>Editar Usuário</h3>
                    </DialogHeader>
                    <AlertDialogCloseButton />
                    <DialogBody>
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                          <ChakraInput
                            name="firstName"
                            label="Nome"
                            placeholder="Digite o Nome"
                            icon={FaUser}
                            isLoading={loadingForm}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <ChakraInput
                            name="lastName"
                            label="Sobrenome"
                            placeholder="Digite o Sobrenome"
                            icon={FaUser}
                            isLoading={loadingForm}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <ChakraSelect
                            name="status"
                            label="Status"
                            placeholder="Selecione"
                            isLoading={loadingForm}
                          >
                            {isAdmin ? (
                              <>
                              <option value={SituationType.ACTIVE}>
                                {SituationType.ACTIVE}
                              </option>
                               <option value={SituationType.INACTIVE}>
                               {SituationType.INACTIVE}
                             </option>
                             </>
                            ) : (
                              <>
                                <option value={SituationType.ACTIVE}>
                                  {SituationType.ACTIVE}
                                </option>
                               
                              </>
                            )}
                          </ChakraSelect>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <ChakraSelect
                            name="profileId"
                            label="Perfis"
                            placeholder="Selecione"
                            isLoading={loadingForm}
                          >
                            {isAdmin ? (
                              profiles?.filter((x) => x.name !== 'Administrador').map(profile => (
                                <option value={profile.id}>
                                  {profile.name}
                                </option>
                              ))
                            ) : (
                              <option value={user.profile.id}>
                              {user.profile.name}
                            </option>
                            )}
                          </ChakraSelect>
                        </Grid>
                        {loadingForm && <LoadingAnimation />}
                        <Grid item xs={6} md={12} />
                      </Grid>
                    </DialogBody>
                    <DialogFooter>
                      <Button
                        onClick={() => handleSubmit()}
                        colorScheme="success"
                        isLoading={loading}
                      >
                        Salvar
                      </Button>
                      <Button
                        colorScheme="red"
                        ref={cancelRef}
                        onClick={onClose}
                      >
                        Cancelar
                      </Button>
                    </DialogFooter>
                  </form>
                </>
              )}
            </Formik>
          </AlertDialogContent>
        </AlertDialog>
      )}
    </>
  );
};

export default DialogSettings;
