/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import api from '../api';
import { ApiResponse, ApiStatus } from '../types';
import { DataCredit } from './data';
import {
  CreditContextData,
  CreditResponse,
  findReportCreditResponse,
  findReportCreditResponseFix,
  MonitorRequest,
  MonitorResponse,
} from './types';

export function parseToString(data: string): string {
  const parsedData = data.replace(/[^\d]/g, '');
  return parsedData;
}

export class AdapterCreditService implements CreditContextData {
  async findAllMonitor(): Promise<ApiResponse<MonitorResponse[]>> {
    const { data } = await api.get<
      {
        id: string;
        monitored: string;
        requestedBy: {
          cnpj: string;
          userId: string;
          user?: string
        };
        status: string;
        documentType: string;
        createdAt: Date;
      }[]
    >(`/company/credit/monitor`);

    const result = data.map(
      ({ id, requestedBy, monitored, status, documentType, createdAt }) => {
        const {  user } = requestedBy;
        return {
          id,
          user: String(user),
          monitored,
          status,
          documentType,
          createdAt,
          requestedBy,
        };
      },
    );

    if (data) {
      return {
        error: false,
        data: result,
      };
    }
    return {
      error: true,
    };
  }

  async deleteMonitor(id: string): Promise<ApiStatus> {
    const response = await api.delete<MonitorResponse[]>(
      `/company/credit/monitor/${id}`,
    );
    if (response) {
      return {
        error: false,
      };
    }
    return {
      error: true,
    };
  }

  async findReportCredit(cnpj: string): Promise<findReportCreditResponse> {
    const { data } = await api.post<findReportCreditResponseFix>(
      `/company/credit/consult`,
      { cnpj: parseToString(cnpj) },
    );
    if (data) {
      return {
        error: false,
        url: data.url?.url,
      };
    }
    return {
      error: true,
    };
  }

  lastestReports(): Promise<CreditResponse> {
    return Promise.resolve({
      error: false,
      data: DataCredit,
    });
  }

  async registerMonitor(monitorRequest: MonitorRequest[] | MonitorRequest): Promise<ApiStatus> {
    const { data } = await api.post<MonitorResponse>(
      `/company/credit/monitor`,
      monitorRequest,
    );
    if (data) {
      return {
        error: false,
      };
    }
    return {
      error: true,
    };
  }
}
