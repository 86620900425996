import * as yup from 'yup';

const lowercaseRegex = /(?=.*[a-z])/;
const uppercaseRegex = /(?=.*[A-Z])/;
const numericRegex = /(?=.*[0-9])/;

export default yup.object().shape({
  newPassword: yup
    .string()
    .required('Informe a Nova Senha')
    .min(8, 'Digite no Mínimo 8 Caracteres')
    .matches(lowercaseRegex, 'Digite no Mínimo 1 Caractere Minúsculo')
    .matches(uppercaseRegex, 'Digite no Mínimo 1 Caractere Maiúsculo')
    .matches(numericRegex, 'Digite no Mínimo 1 Caractere Númerico'),
  confirmPassword: yup
    .string()
    .required('Confirme sua Nova Senha')
    .oneOf([yup.ref('newPassword'), null], 'Senhas devem ser iguais'),
});
