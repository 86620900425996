import { v4 as uuidv4 } from 'uuid';
import { ProfileEntity } from '../models';

const nowDate = new Date();

export const DataProfile: ProfileEntity[] = [
  {
    id: 'rw-profile',
    name: 'Admin',
    planId: uuidv4(),
    date: nowDate,
    groupPermissions: [
      {
        id: uuidv4(),
        name: 'Crédito',
        permissions: [
          { id: '1', name: 'Relatório de Crédito' },
          { id: '2', name: 'Monitore' },
          { id: '3', name: 'Últimos Relatórios' },
        ],
      },
      {
        id: uuidv4(),
        name: 'Serasa',
        permissions: [
          { id: '1', name: 'Consulta Serasa' },
          { id: '2', name: 'Últimos Relatórios' },
        ],
      },
      {
        id: uuidv4(),
        name: 'Configurações',
        permissions: [{ id: '1', name: 'Usuários' }],
      },
    ],
  },
  {
    id: 's-request-credit-analyze',
    name: 'Analista',
    planId: uuidv4(),
    date: nowDate,
    groupPermissions: [
      {
        id: uuidv4(),
        name: 'Crédito',
        permissions: [
          { id: '1', name: 'Relatório de Crédito' },
          { id: '2', name: 'Monitore' },
        ],
      },
      {
        id: uuidv4(),
        name: 'Serasa',
        permissions: [{ id: '1', name: 'Consulta Serasa' }],
      },
      {
        id: uuidv4(),
        name: 'Configurações',
        permissions: [{ id: '1', name: 'Usuários' }],
      },
    ],
  },
  {
    id: uuidv4(),
    name: 'Tester',
    planId: uuidv4(),
    date: nowDate,
    groupPermissions: [
      {
        id: uuidv4(),
        name: 'Crédito',
        permissions: [{ id: '1', name: 'Relatório de Crédito' }],
      },
      {
        id: uuidv4(),
        name: 'Serasa',
        permissions: [{ id: '1', name: 'Consulta Serasa' }],
      },
      {
        id: uuidv4(),
        name: 'Configurações',
        permissions: [{ id: '1', name: 'Usuários' }],
      },
    ],
  },
];
