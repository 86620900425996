export const getErrorUtils = (errors: string[], inputName: string): any => {
  const parsedInputName = inputName.split('.');
  function getErrorObject(error: any, names: string[]): undefined {
    const [name, ...others] = names;

    if (!error) {
      return undefined;
    }

    if (names.length === 1) {
      return error[name];
    }
    return getErrorObject(error[name], others);
  }

  return getErrorObject(errors, parsedInputName);
};
