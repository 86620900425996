import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { setupCache, ISetupCache } from 'axios-cache-adapter';
import axiosRetry from 'axios-retry';
import { trackHttpError } from './analytics/plausible';

// Create `axios-cache-adapter` instance
const cache: ISetupCache = setupCache({
  maxAge: 1 * 60 * 1000, // 1 minutos para expiração,
});

// Create `axios` instance passing the newly created `cache.adapter`
const api: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  adapter: cache.adapter,
});

// cria  interceptor de hTTP erros.
const interceptorHttpError = api.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error: { response: AxiosResponse; config: AxiosRequestConfig }) {
    trackHttpError({
      status: error?.response?.status,
      url: error?.config?.url,
      method: error?.config?.method,
    });
    return Promise.reject(error);
  },
);

// ejeta interceptor de hTTP erros.
api.interceptors.request.eject(interceptorHttpError);

// retry request
axiosRetry(api, { retries: 3 });

export default api;
