/* eslint-disable no-shadow */
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import { useKeycloak } from '@react-keycloak/web';
import { AuthContextData } from './types';
import { ADMNISTRADOR, ANALISTA, appPrefix, CONFIG_MENU_ADMIN } from './constants';
import api from '../../services/api';
import { useConfig } from '../../services/config';
import {
  ListProfilesResponse,
  MyCompanyResponse,
  MyProfileResponse,
} from '../../services/config/types';
import * as Data from '../../utils/data';
import { SideMenuCategory } from '../../utils/data';

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

export const AuthProvider: React.FC = ({ children }) => {
  const [user, setUser] = useState< MyProfileResponse | undefined>();
  const [company, setCompany] = useState<MyCompanyResponse | undefined>();
  const { keycloak, initialized } = useKeycloak();
  const [hasToken, setHasToken] = useState(false);
  const [sideMenuData, setSideMenuData] = useState<SideMenuCategory[]>(
    Data.SideMenuData,
  );
  const [expirationTime, setExpirationTime] = useState<number | undefined>();
  const [isAdmin, setIsAdmin] = useState<boolean>(false);

  const { getMyInfoAuth } = useConfig();

  const setPermissionsMenu = (profile: ListProfilesResponse) => {
    if(profile.name === ADMNISTRADOR && profile.editable === false) {
      setIsAdmin(true);
    }
    if (profile.name !== ADMNISTRADOR && profile.name !== ANALISTA) {
      const newSideMenuData = Data.SideMenuData.map(Category => {
        const options = Category.features.filter(option =>
          profile.permissions.includes(option.id),
        );
        const newOptions = Category.features.map(option => {
          const { id, name, path, disabled, redirect } = option;
          if (options.find(oldOption => oldOption === option)) {
            return { id, name, path, disabled, redirect };
          } else {
            return { id, name, path, disabled: true, redirect };
          }
        });
        const { id, name, icon, disabled, inComing } = Category;
        return {
          id,
          name,
          icon,
          disabled,
          inComing,
          features: newOptions,
        };
      });
      setSideMenuData(newSideMenuData);
    } else if (profile.name === ANALISTA) {
      const newSideMenuData = Data.SideMenuData.filter(
        Category => Category.name !== CONFIG_MENU_ADMIN,
      );
      setSideMenuData(newSideMenuData);
    }
  };

  useEffect(() => {
    setHasToken(false);
    if (!initialized) {
      return;
    }
    const fetchUserInfo = async () => {
      api.defaults.headers.common["Authorization"]= `Bearer ${keycloak.token}`;
      const authInfo = await getMyInfoAuth()
      if (authInfo.data?.profile) {
        setUser({...authInfo?.data?.profile, ...authInfo?.data?.user});
        const profilesCompany = authInfo.data?.profilesCompany;
        if (profilesCompany) {
          const profileSelected = profilesCompany.find(
            profile => profile.id === authInfo?.data?.profile?.id,
          );
          if (profileSelected) {
            setPermissionsMenu(profileSelected);
          }
        }
      }
      if (authInfo.data?.company) {
        setCompany(authInfo.data?.company);
      }
      setHasToken(true);
    };

    if (
      initialized &&
      keycloak.authenticated &&
      keycloak.tokenParsed &&
      keycloak.tokenParsed.exp
    ) {
      fetchUserInfo();
      setExpirationTime(keycloak.tokenParsed.exp * 1000 - 60000);
    }
   
  }, [keycloak, initialized]);

  const login = useCallback(() => {
    keycloak.login();
  }, [keycloak]);

  const logout = useCallback(() => {
    keycloak.logout().then(() => {
      localStorage.removeItem(`${appPrefix}:user`);
      setHasToken(false);
    });
  }, [keycloak]);

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated: !!keycloak.authenticated,
        initialized,
        token: keycloak.token,
        hasToken,
        user,
        company,
        sideMenuData,
        roles: keycloak.realmAccess,
        login,
        logout,
        isAdmin
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
}


