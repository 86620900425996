import axios, { AxiosInstance } from 'axios';

interface Config {
  status: string | number;
  url?: string;
  method?: string;
}

interface PropsHtttpEror {
  status: string | number;
  url?: string;
  method?: string;
}

interface EventPayload {
  readonly name: string;
  readonly url: Location['href'];
  readonly domain: Location['hostname'];
  readonly screen_width: Window['innerWidth'];
  readonly props: { [propName: string]: string | number | boolean } | string;
}

const HOST = process.env.REACT_APP_PLAUSIBLE_API_HOST;
const DOMAIN = process.env.REACT_APP_PLAUSIBLE_DOMAIN;

const baseAnalitics: AxiosInstance = axios.create({
  baseURL: HOST + '/api',
  headers: {
    'Content-Type': 'application/json, text-plain',
  },
});
const trackHttpError = async function (config: Config): Promise<any> {
  if (!HOST || !DOMAIN) {
    console.error('Falta variaveis de ambiente plausible');
    return null;
  }
  try {
    const props: PropsHtttpEror = {
      status: config?.status,
      url: config?.url,
      method: config?.method,
    };

    const payload: Required<EventPayload> = {
      name: 'httperror',
      url: window.location.href,
      domain: DOMAIN,
      screen_width: window.innerWidth,
      props: JSON.stringify(props),
    };
    return await baseAnalitics.post('/event', payload);
  } catch {
    /** retorna null para nao travar a corrente de Promise */
    return null
  }
};

export { trackHttpError };
