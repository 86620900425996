/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { RiGuideLine } from 'react-icons/ri';
import {
  Category,
  Container,
  Content,
  LogoCompany,
  LogoTeddy,
  Option,
  OptionLink,
  Options,
  TutorialLink,
} from './styles';
import LogoTeddyImgOpenFinance from '../../assets/img/teddy-open-finance.png'

import { FadeInAnimation } from '../../utils/variants';
import { MenuContext } from '../../contexts';
import TeddyLogo from '../../assets/svg/teddy-open-banking.svg';
import { useAuth } from '../../hooks/auth';

const SideMenu: React.FC = () => {
  const { company, sideMenuData } = useAuth();
  const { setIsOpen, isMobile } = useContext(MenuContext);
  const [SideMenuData, setSideMenuData] = useState(sideMenuData);

  useEffect(() => {
    setSideMenuData(sideMenuData);
  }, [sideMenuData]);

  return (
    <Container>
      <motion.div
        variants={FadeInAnimation}
        initial="hidden"
        animate="visible"
        exit="exit"
        style={{ width: '280px' }}
      >
        <Link to="/dashboard" onClick={() => isMobile && setIsOpen(false)}>
          <LogoCompany>
            {company ? (
              <img src={company.logoImage} alt={company.name} />
            ) : (
              <img src={TeddyLogo} alt="Teddy Open Banking" />
            )}
          </LogoCompany>
        </Link>
        <LogoTeddy>
          <span>by</span>
          <a href="https://teddydigital.io/" target="_blank" rel="noreferrer">
            <img src={LogoTeddyImgOpenFinance} alt="Teddy" />
          </a>
        </LogoTeddy>
      </motion.div>
      <Content
        variants={FadeInAnimation}
        initial="hidden"
        animate="visible"
        exit="exit"
      >
        {SideMenuData.map(({ icon: Icon, ...SideMenuCategory }) => (
          <Category
            disabled={SideMenuCategory.disabled}
            key={SideMenuCategory.id}
          >
            <div>
              <Icon boxSize={6} />
              <h2 key={SideMenuCategory.id}>{SideMenuCategory.name}</h2>
              {SideMenuCategory.inComing && <span>Em Breve</span>}
            </div>

            <Options disabled={SideMenuCategory.disabled}>
              {SideMenuCategory.features.map(SideMenuOption => (
                <>
                  {SideMenuOption.redirect && (
                    <OptionLink
                      href={SideMenuOption.path}
                      target="_blank"
                      rel="noreferrer"
                      onClick={() => isMobile && setIsOpen(false)}
                      disabled={SideMenuOption.disabled}
                      key={SideMenuOption.name}
                    >
                      {SideMenuOption.name}
                    </OptionLink>
                  )}
                  {!SideMenuOption.redirect && (
                    <Option
                      to={SideMenuOption.path}
                      onClick={() => isMobile && setIsOpen(false)}
                      disabled={SideMenuOption.disabled}
                      key={SideMenuOption.name}
                    >
                      {SideMenuOption.name}
                    </Option>
                  )}
                </>
              ))}
            </Options>
          </Category>
        ))}
      </Content>
      <TutorialLink
        variants={FadeInAnimation}
        initial="hidden"
        animate="visible"
        exit="exit"
        href="https://teddydigital.io/tutorial-analise-de-credito/"
        target="_blank"
        rel="noreferrer"
      >
        <RiGuideLine size={24} />
        Central de ajuda
      </TutorialLink>
    </Container>
  );
};

export default SideMenu;
