import { IGroupPermissionApi } from './types';

export const generateApiPermissions = (
  groupPermissions: IGroupPermissionApi[],
  permissionOld?: Array<string>,
): Array<string> => {
  const permissions: Array<string> = [];

  groupPermissions.forEach(value => {
    value.permissions.forEach(permission => {
      if (permission.enabled) {
        permissions.push(permission.code);
      }
    });
  });
  if (permissions.length === 0 && permissionOld) {
    return permissionOld;
  }
  return permissions;
};
