import React, { createContext, useContext, useMemo } from 'react';
import { AdapterCreditService } from './adapter.credit.service';
import { FakerCreditService } from './faker.credit.service';
import { CreditContextData } from './types';

const CreditContext = createContext<CreditContextData>({} as CreditContextData);

const factoryCreditService = (): CreditContextData => {
  if (process.env.NODE_ENV === 'development') {
    if (process.env.REACT_APP_HOMOLOG) {
      return new AdapterCreditService();
    }
    return new FakerCreditService();
  }
  return new AdapterCreditService();
};

export const CreditProvider: React.FC = ({ children }) => {
  const service = useMemo(() => factoryCreditService(), []);

  return (
    <CreditContext.Provider value={service}>{children}</CreditContext.Provider>
  );
};

export function useCredit(): CreditContextData {
  const context = useContext(CreditContext);

  if (!context) {
    throw new Error('useCredit must be used within an CreditProvider');
  }

  return context;
}
