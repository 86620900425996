/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import api from '../api';
import { ApiStatus, ApiResponse } from '../types';
import {
  ConfigContextData,
  EditUserRequest,
  ListProfilesResponse,
  ListUsersResponse,
  MyInfoAuthResponse,
  MyProfileRequest,
  NewUserRequest,
} from './types';

async function getUserInfo() {
  const { data } = await api.get<{
    user: { id: string; cnpj: string; email: string };
  }>('/profile');
  return data.user;
}

function parseNumber(data: string) {
  const parsedNumber = data
    .replace(/\(/g, '')
    .replace(/\)/g, '')
    .replace(/-/g, '')
    .replace(/ /g, '');
  return parsedNumber;
}

export class AdapterConfigService implements ConfigContextData {
  async newPassword(newPassword: string): Promise<ApiStatus> {
    const response = await api.patch(`/my-profile/password`, {
      newPassword,
    });
    if (response) {
      return {
        error: false,
      };
    }
    return {
      error: true,
    };
  }

  async newPasswordEmployee(userId: string, newPassword: string): Promise<ApiStatus> {
    const response = await api.patch(`/company/users/${userId}/password`, {
      newPassword,
    });
    if (response) {
      return {
        error: false,
      };
    }
    return {
      error: true,
    };
  }

  //usado no dialog-settings
  public async listProfilesOption(): Promise<
    ApiResponse<ListProfilesResponse[]>
  > {
    const { data } = await api.get<ListProfilesResponse[]>(`/company/profiles`);
    if (data) {
      return {
        error: false,
        data,
      };
    }
    return {
      error: true,
    };
  }

  public async getOneProfilesOption(
    profileId: string,
  ): Promise<ApiResponse<ListProfilesResponse>> {
    const { data } = await api.get<ListProfilesResponse>(
      `/company/profiles/${profileId}`,
    );
    if (data) {
      return {
        error: false,
        data,
      };
    }
    return {
      error: true,
    };
  }

  deleteUser(id: string): Promise<ApiStatus> {
    return Promise.resolve({
      error: false,
    });
  }

  async editUser(
    id: string,
    { firstName, lastName, profileId, status }: EditUserRequest,
  ): Promise<ApiStatus> {
    const response = await api.patch(`/company/users/${id}`, {
      firstName,
      lastName,
      profileId,
      status,
    });
    if (response) {
      return {
        error: false,
      };
    }
    return {
      error: true,
    };
  }

  async listUsers(): Promise<ApiResponse<ListUsersResponse[]>> {
    const { data } = await api.get<
      {
        id: string;
        email: string;
        firstName: string;
        lastName: string;
        status: string;
        cellPhone: string;
        phone: string;
        profileId: string | any;
        createdAt: string;
      }[]
    >(`/company/users`);

    const result = data.map(
          ({
            email,
            firstName,
            lastName,
            id,
            status,
            cellPhone,
            phone,
            profileId,
            createdAt,
          }) => {
              return {
                id,
                email,
                name: `${firstName} ${lastName}`,
                status,
                cellphone: cellPhone,
                phone,
                profile: profileId && profileId?.name ? profileId : { id: 'none', name: 'Desconhecido', permissions: [] },
                date: createdAt,
              };
          },
        );
        if(result) {
          return {
            error: false,
            data: result,
          };
        }
        return {
          error: true,
        };
  }

  async newUser({
    email,
    firstName,
    lastName,
    password,
    profileId,
    status,
    landline: phone,
    phone: cellphone,
  }: NewUserRequest): Promise<ApiStatus> {
    const cellPhone = parseNumber(cellphone);
    await api.post(`/company/users`, {
      email,
      firstName,
      lastName,
      password,
      profileId,
      status,
      cellPhone,
      phone: parseNumber(phone),
    });
    return {
      error: false,
    };
  }

  editMyProfile(myProfileRequest: MyProfileRequest): Promise<ApiStatus> {
    return Promise.resolve({
      error: false,
    });
  }
  async getMyInfoAuth (): Promise<ApiResponse<MyInfoAuthResponse>> {
    const { data } = await api.get<MyInfoAuthResponse>(`/my-profile`);
    if (data) {
      return {
        data,
        error: false,
      };
    }
    return {
      error: true,
    };
  }
}
